import '/scss/app.scss';

import jquery from 'jquery';
window.jQuery = window.$ = jquery;
import 'popper.js';
import 'bootstrap';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
require("@fancyapps/fancybox")



$(".menu-collapsed").click(function() {
  $(this).toggleClass("menu-expanded");
});

    $(document).ready(function(){
        $("#myModal").modal('show');
    });

$("#myModal").on('hidden.bs.modal', function (e) {
    $("#myModal iframe").attr("src", $("#myModal iframe").attr("src"));
});


function toggleDropdown (e) {
  const _d = $(e.target).closest('.dropdown'),
      _m = $('.dropdown-menu', _d);
  setTimeout(function(){
    const shouldOpen = e.type !== 'click' && _d.is(':hover');
    _m.toggleClass('show', shouldOpen);
    _d.toggleClass('show', shouldOpen);
    $('[data-toggle="dropdown-custom"]', _d).attr('aria-expanded', shouldOpen);
  }, e.type === 'mouseleave' ? 300 : 0);
}

$('body')
  .on('mouseenter mouseleave','.dropdown',toggleDropdown)
  .on('click', '.dropdown-menu a', toggleDropdown);


$("[data-fancybox]").fancybox({
  slideShow: {
    autoStart: true,
    speed: 2500
  },
  loop:true
});